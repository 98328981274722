.alta-taller {
    display: flex;
    justify-content: center;
    padding: 100px 50px 30px;
    width: 100%;

    & > div {
        max-width: 480px !important;
    }
    
    @media (max-width: 500px) {
        padding: 90px 35px 20px;
    }
}