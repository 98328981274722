@import '../../styles/main.scss';
@import '../../styles/mixins.scss';

.send-budget {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 45px 36px;
    gap: 45px;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &--img {
            height: 18px;
            width: 141px;
        }

        h1 {
            @media (min-width: 490px) {
                font-size: 1.6rem !important;
            }
        }
    }

    &__form-client {
        width: 100%;
        padding-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        &--container {
            width: 100%;

            @media (min-width: 769px) {
                max-width: 600px;
            }

            h2 {
                font-size: 25px !important;
                color: $fontSecondary;
                font-weight: 400 !important;
                padding-bottom: 11px;

                @media (min-width: 769px) {
                    font-size: 30px !important;
                }
            }

            form {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 28px;

                button {
                    @include button(100%, 45px, 10px);
                    position: relative;
                }
            }
        }
    }

    .sent-done {
        min-height: 60vh;

        &__container {
            @media (max-width: 576px) {
                width: 100% !important;
            }
        }
    }

    .captcha {
        @media (min-width: 490px) {
            justify-content: flex-start !important;
        }
    }
}
