$sans-serif: sans-serif;
$rubik: Rubik, #{$sans-serif};

@mixin font-face($name, $path, $format, $weight) {
  @font-face {
    font-family: $name;
    src: url("#{$path}") format("#{$format}");
    font-weight: $weight;
    font-style: normal;
  }
}

@include font-face('Rubik', '../../fonts/rubik/Rubik-Light.ttf', 'truetype', 300);
@include font-face('Rubik', '../../fonts/rubik/Rubik-Regular.ttf', 'truetype', 400);
@include font-face('Rubik', '../../fonts/rubik/Rubik-Medium.ttf', 'truetype', 500);
@include font-face('Rubik', '../../fonts/rubik/Rubik-Bold.ttf', 'truetype', 700);
@include font-face('Rubik', '../../fonts/rubik/Rubik-SemiBold.ttf', 'truetype', 800);
@include font-face('Rubik', '../../fonts/rubik/Rubik-ExtraBold.ttf', 'truetype', 900);

h1 {
  font-size: 1.25rem;
  font-weight: 700;
}