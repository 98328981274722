@import '../../../../styles/base/color';
@import '../../../../styles/mixins';

.trabaja-con-nosotros {
    padding: 0 !important;
    margin-top: 3rem !important;
    margin-bottom: 1.1rem;

    @media (max-width: 768px) {
        margin-top: 6.5rem;
        padding: 0 !important;
        margin-bottom: 2.3rem;
    }

    &__container-img {
        @media (min-width:768px) {
            align-items: flex-start !important;
        }
    }

    .section-info {
        
        @media (min-width: 768px) {
            min-height: 100vh;
            height: 100%;
        }
    }
    
    .seccion {
        img { z-index: -1; }
    }
}

.trabaja-con-nosotros.seccion {
    padding-top: 0px !important;
}

.form-group {
    h5 { 
        color: $primary !important; 
        font-size: 1rem !important;
    }
}

.section-info {

    @media (max-width: 768px) {
        min-height: 256px;
        text-align: center;
        padding: 50px 0;
        margin-bottom: 20px;
    }
}

.section-info-text {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    
    @media (min-width: 768px) {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
        
    }

    @media (min-width: 1109px) {
        max-width: 450px;  
        align-self: center; 
        padding-right: 0rem !important;
        padding-left: 0rem !important;
    }

    @media (min-width: 1500px) {
        padding-right: 7rem !important;
        padding-left: 7rem !important;
        max-width: none;
    }

    &__black {
        color: $fontPrimary;
    }
}

.section-info-text-content > h5 {
    @media (max-width: 768px) {
        color: $fontPrimary !important; 
        font-size: 1rem !important;
    }
}

.section-info-text-content > h3 {
    @media (max-width: 768px) {
        font-size: 1.6rem !important;
    }
}

.section-info-video-content {
    padding-top: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        padding: 60px 3rem 0;
    }

    // @media (min-width: 1109px) {
    //     padding: 60px 4rem 0;
    // }
    
    // @media (min-width: 1500px) {
    //     padding: 60px 5rem 0;
    // }
    
    // @media (min-width: 1670px) {
    //     padding: 60px 7rem 0;
    // }

    iframe {
        border-radius: 6px;
        height: 170px;

        @media (min-width: 500px) {
            height: 210px;
            width: 370px;
        }

        @media (min-width: 768px) {
            height: 180px;
            width: 100%;
        }

        @media (min-width: 900px) {
            height: 200px;
            width: 100%;
        }

        @media (min-width: 1109px) {
            height: 255px;
            max-width: 450px;
        }
    }
}

.form-button {
    justify-content: left;
    @media (max-width: 768px) {
        justify-content: center;
    }
}

.posiciones-activas {
    width: 100%;
    padding-top: 32px;

    @media (min-width: 768px) {
        padding-top: 16px;
    }
    
    @media (min-width: 1064px) {
        width: 491px;

    }

    h5 {
        text-transform: uppercase !important;
        text-align: center;
        font-size: 24px !important;
        padding-bottom: 24px;

    }

    &__position-container {
        border-bottom: 1px solid $light-gray;
        padding: 16px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background-color: $hoverColor;

            h6 {
                color: $primary;
            }

            span {
                color: $primary;
            }
        }

        &--text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            &--title {
                margin-bottom: 3px !important;
            }

            &--location {
                display: flex;
                gap: 5px;
                font-size: 13px !important;

                &--icon {
                    color: $primary;
                }
            }
        }

        &--arrow {
            color: $primary;
        }
    }

    &__no-ofertas {
        text-align: center;
        color: $light-gray !important;
        font-style: italic;
        padding: 50px 0 62px;
        border-bottom: 1px solid $light-gray;
        width: 80%;
        margin: auto;
    }

}

.trabaja-con-nosotros-form {
    &__titles {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        padding: 62px 0 36px;

        @media (min-width:768px) {
            text-align: center;
        }

        &--title {
            font-size: 24px !important;
        }

        &--subtitle {
            font-size: 15px !important;
        }
    }

    @media (min-width:768px) {
        flex-direction: column;
        padding-bottom: 16px;
    }
}

.skeleton-posiciones-activas {
    height: 74px;
    border-bottom: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 6px;

        &--title {
            margin: 0 !important;
            width: 190px;
            height: 15px;
        }

        &--location {
            width: 100px;
            height: 12px;
        }
    }

    &__arrow {
        width: 10px;
        height: 14px;
    }

    &__loading-animation {
        @include skeletonBasicsAnimation($hoverColor, $hoverColor);
    }
}
