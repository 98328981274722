$primary: #215732;
$primary-hover: #20532f;
$primaryLight: #DAF9E4;
$default: #212529;
$fontPrimary: #000;
$fontSecondary: #333;

$backgroundGray: #F5F5F5;
$footer: #212529;
$white: #FFF;
$light-gray: #9e9b9b;
$green: #29a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #62B1F6;
$boxShadow: rgba(171, 171, 171, .25);
$hoverColor: #9e9b9b1d;

$grayColor: #525252;