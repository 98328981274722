@import '../../../../styles/base/color';


.contacto {
    .contacto__contact{
        display: flex;
        flex-direction: row;
        padding:  0;

        @media (max-width: 768px) {
            flex-direction: column;
            padding: 0 2rem;
        }

        a {
            font-weight: 500;
            font-size: 1rem;
            
            @media (max-width: 459px) {
                font-size: 0.8rem;
             }

             @media (max-width: 412px) {
                font-size: 0.75rem;
             }
        }

        .icon {
            background-color: $white;
            padding: 15px;
            box-shadow: 0px 0px 10px $boxShadow;
            border-radius: 12px;
            margin-bottom: 0;
            margin-right: 8px;

            @media (max-width: 768px) {
               margin-bottom: 0.6rem;
               margin-right: 0;
            }
            
            img { width: 100%; height: 100%; }
        }

    }
}

.contacto.seccion {
    padding-top: 0px !important;
}