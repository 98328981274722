@import '../../../../../styles/base/color';

.simple-spinner {
  width: 28px;
  height: 28px;
  position: absolute;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
}

.simple-spinner span {
  display: block;
  width: 28px;
  height: 28px;
  border: 3px solid transparent;
  border-radius: 50%;
  border: 2px;
  border-right: 1px solid $white;
	opacity: 0.8;
  animation: spinner-anim 0.8s linear infinite;
}

@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}