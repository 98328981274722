@import '../base/color';
@import '../base/typography';

$theme-colors: (
  "primary": $primary,
  "default": $light-gray,
  "danger": $danger,
  "warning": $warning,
  "success": $primary,
  "info": $info
);

:root {
  --tab-active: #eaf3ff;
  --table-striped-active: #eaf3ff;
}

@import "~bootstrap/scss/bootstrap";
@import 'react-toastify/dist/ReactToastify.css';