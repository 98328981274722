

.tipoIncidencia-container {
    cursor: pointer;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    &-item {
        padding: 18px 32px;
        border: 1px solid #215732;
        border-radius: 8px;
        height: 100%;

        h4 {
            font-weight: 700;
            font-size: 1rem !important;
        }

        p {
            font-weight: 300 !important;
            font-size: 0.875rem !important;
            text-transform: none !important;
            text-align: center;
        }
    }
}

/* HIDE RADIO */
.tipoIncidencia-container > input{ 
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
}
.tipoIncidencia-container > input + div{ /* DIV STYLES */
    cursor:pointer;
}
.tipoIncidencia-container > input:checked + div{ /* (RADIO CHECKED) DIV STYLES */
    background-color: #daf9e4;
}