@import '../../../../../styles/base/color';

.success-icon {
  display: inline-block;
  width: 4em;
  height: 4em;
  font-size: 20px;
  border-radius: 50%;
  border: 2px solid $primary;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);

  &__tip,
  &__long {
    display: block;
    position: absolute;
    height: 3px;
    background-color: $primary;
    border-radius: 5px;
  }

  &__tip {
    width: 1.2em;
    top: 2.15em;
    left: 0.7em;
    transform: rotate(45deg);
    animation: tipInPlace 300ms ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 180ms;
    visibility: hidden;
  }

  &__long {
    width: 2em;
    transform: rotate(-45deg);
    top: 1.85em;
    left: 1.375em;
    animation: longInPlace 140ms ease-in-out;
    animation-fill-mode: forwards;
    visibility: hidden;
    animation-delay: 300ms + 140ms;
  }
}

@keyframes showSuccess {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes tipInPlace {
    from {
        width: 0em;
        top: 0em;
        left: -0.8em;
    }
    to {
        width: 1.2em;
        top: 2.15em;
        left: 0.7em;
        visibility: visible;
    }
}

@keyframes longInPlace {
    from {
        width: 0em;
        top: 2.55em;
        left: 1.6em;
    }
    to {
        width: 2em;
        top: 1.85em;
        left: 1.375em;
        visibility: visible;
    }
}