@import '../../../../../styles/main.scss';

.form-input {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    height: 45px;
    font-size: 14px;
    padding: 10px;
    outline: none;
    border-bottom: 1px solid gray !important;
    box-sizing: border-box;
    border-radius: 5px;
  }

  input::placeholder {
    opacity: 0 !important;
  }

  label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: gray;
    letter-spacing: 1px;
    transition: 0.3s;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    top: 0;
    font-size: 0.8rem;
    color: $primary;
    background: #fff;
    padding: 7px;
  }

  input:focus {
    border: 2px solid $primary !important;
    border-radius: 5px !important;
  }

  span {
    color: $danger !important;
    text-align: start;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    z-index: 2;
  }
}
