@import '../../../../../../styles/base/color';

.post-detail {
    &-wrapper { 
        margin-top: 60px;

        @media (max-width: 768px) {
            flex-direction: column-reverse; 
        }
     }

    &-info {
        p { text-align: left !important; }

        .fecha {
            h1 { 
                line-height: 2.85rem !important;
                margin-bottom: 5px !important; 
            }

            p { 
                font-weight: 700 !important;
                text-transform: capitalize;
                color: $primary !important;
            }
        }

        .rrss {
            p { font-size: 12px !important; }

            .rrss-wrapper {
                a {
                    margin-bottom: 15px;

                    .rss-btn {
                        padding: 5px;
                        border-radius: 6px;
                        box-shadow: 0px 0px 6px 0px $boxShadow;
                    }
                }
            }
        }

        
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            
            .container-fecha-por {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;

                .fecha {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 50%;

                    h1 {
                        font-size: 2.8rem !important;
                        margin-bottom: 0 !important;
                        padding-right: 0.3rem !important;
                    }
                }

                .por {
                    display: flex;
                    width: 50%;
                    
                    span {
                        padding-left: 0.3rem;
                    }
                }
            }

            .rrss {
                display: flex !important;
                flex-direction: column !important;
                align-items: center !important;
                justify-content: center !important;
                padding-top: 2rem;
                div {
                    flex-direction: row !important;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                }

                .rss-btn {
                    padding: 9px !important;
                }

            }

        }
            
    }

    .post-title {
        max-width: 50%;
        padding-bottom: 40px;

        h1 { color: $white; }

        @media (max-width: 1199px) {
            max-width: 70%;
        }

        @media (max-width: 768px) {
            max-width: 70%;
        }
    }
    

    .post-img {
        min-height: 450px;
        background: linear-gradient(0deg, rgba(33, 87, 50, 0.6), rgba(33, 87, 50, 0.6)), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        background-blend-mode: multiply, normal, normal;

        @media (max-width: 768px) {
            min-height: 250px;
        }

        img {
            position: absolute;
            z-index: -1;
        }
    }

    &-content {
        ul {
            list-style: square !important;
            margin-top: 5px;

            li { 
                list-style-type: square !important; 
                margin-bottom: 5px;
            }
        }

        h2, h3, h4, h5 {
            font-size: 1.375rem !important;
            line-height: 1.385rem !important;
            font-weight: 700 !important;
            color: $primary;
            margin: 30px 0 20px 0;
        }

        p { 
            text-align: justify !important;
        }

        a {
            text-decoration: underline !important;
            font-weight: 300 !important;
        }

        p, li {
            line-height: 22px;
        }

        figure {
            margin: 30px 0;

            img {
                width: 100%;
                max-height: 328px;
                object-fit: cover;
            }

            figcaption {
                font-size: 12px;
            }

            iframe {
                width: 100%;
            }
        }

        ol, ul {
            margin-bottom: 0;
        }

        .wp-block-buttons {
            padding: 2rem 0rem;
            
            .wp-block-button {
                border: 3px solid $primary;
                background-color: $primary;
                padding: 1rem 2rem !important;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                cursor: pointer;
    
                a {
                    color: $backgroundGray;
                    font-size: 1.05rem;
                    font-weight: 500 !important;
                    text-align: center;
                    line-height: 25px;

                    &:link, &:visited, &:active {
                        text-decoration:none !important;
    
                    }
                }

                &:hover {
                  background-color: $white;

                  a {
                      color: $primary;  

                  }
                }
            }
        }

    }

    .back {
        margin: 80px 0;

        .btn-outline-primary:hover {
            svg { color: $white !important; }
        }
    }

    svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
        margin-right: 0.7rem;
    }

    .btn-mobile {
        display: none !important;

        @media (max-width: 768px) {
            display: flex !important;
            margin-top: 3rem;
            margin-bottom: 4rem;
        }
    }
    .btn-desktop {
        display: flex !important;

        @media (max-width: 768px) {
            display: none !important;
        }

    }
    

    .separador-mb {
        @media (max-width: 768px) {
        width: 110px !important;
        height: 1px !important;
        margin: 20px 0 !important;
        background-color: $primary !important;
        }
    }
}