@import '../../../styles/base/color';

.incidents {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px;
  gap: 40px;

  @media (max-width: 768px) {
    padding: 40px;
  }

  @media (max-width: 576px) {
    gap: 30px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    &--img {
      height: 28px;
      width: 219px;  
    }
  }

  h1 {
    color: $fontSecondary;
    font-size: 28px !important;
    text-align: center;
  }

  &__container {
    box-shadow: 2px 2px 10px $boxShadow;
    border-radius: 32px;
    width: 900px;
    padding: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media (max-width: 1300px) {
      width: 75%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 576px) {
      box-shadow: none;
      padding: 46px;
    }

    &--each {
      display: flex;
      flex-direction: column;
      gap: 26px;

      p {
        font-weight: 600 !important;
        line-height: 24px !important;

        @media (max-width: 576px) {
          font-size: 14px !important;
        }
      }

      label {
        font-size: 28px !important;
      }
    }
  }

  &__btn {
    background-color: $primary;
    color: $white;
    padding: 14px;
    border-radius: 6px;
    width: 197px;
    height: 47px;
    position: relative;

    &:active {
      -webkit-transform: scale(0.99);
      transform: scale(0.99);
  }
  }
}
