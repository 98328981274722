@import '../../../../../styles/base/color';

.footer {
    padding: 30px 0;
    background-color: $default;

    @media screen and (max-width: 768px) {
        padding: 30px 25px;
    }

    &-logo {
        height: 18px;
        width: 144px;
    }

    .links, .info { 
        color: $white !important; 

        small { 
            font-size: 0.875rem !important; 
            color: $white !important;

            &.copyright { font-size: 0.775rem !important; }
        }

        a {
            text-decoration: none;
            color: #FFF;
            font-size: 0.75rem !important;

            &:hover { color: #f8fafc !important; }
        }
    }

    .info {
        i {
            margin-left: 10px;

            @media screen and (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
}