@import '../../../../styles/base/color';

.vende {
    .form-container { 
        margin-top: -5%; 

        @media (max-width: 768px) {
            margin-top: -10%; 
        }

        @media (max-width: 425px) {
            margin-top: -20%; 
        }
    }

    .form-wrapper {
        background-color: $white;
        padding: 48px 20px;
        border-radius: 12px;
        box-shadow: 0px 0px 20px $boxShadow;
        
        .step-wrapper {
            height: 250px;
        }

        .steps {
            .step {
                padding: 12px 20px;
                border-radius: 50%;
                border: 1px solid $primary;
                background-color: $white;
                cursor: pointer;
    
                &.active {
                    background-color: $primaryLight;
                    -webkit-transition: background-color 300ms linear;
                    -ms-transition: background-color 300ms linear;
                    transition: background-color 300ms linear;
                }
    
                h5 { font-weight: 700; } 
            }
    
            .divider {
                width: 100%;
                height: 2px;
                background-color: $primary;
            }
        }
    }

    .features {
        height: 90vh;

        .features-list {
            column-gap: 25px;
        }
    }

    .vende-coche-fotos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 95px !important;
        border: 1px solid $primary;
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        padding: 10px !important;
        border-radius: 5px !important;
        background-color: #FFF;
        color: $primary !important;
        opacity: .8;       
        
        label {
            color: $default !important;
            border: 1px solid $default;
            padding: 0.1rem 0.7rem;
            margin: 0 !important;
            border-radius: 5px !important;
            cursor: pointer;
        }

        input {
            display: none;
        }

    }

    .vende-coche-attachments {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px 0;
        gap: 3px;

        &__title {
            font-size: 14px !important;
            color: $grayColor !important;
            padding-left: 2px;
        }

        &__archivos {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 16px;
            gap: 2px;
            
            span {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 3px;
                font-size: 13px;
                color: $grayColor;
            }
        }
    }

    .vende-coche-submit-btn {
        position: relative;
        width: 149px;
        height: 40px;

        @media (min-width: 768px) {
           height: 42px;
        } 
    }
}
