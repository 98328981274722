@import '../../../../../../styles/main.scss';

.places-search {
    width: 100%;
    position: relative;

    input {
        width: 100%;
    }

    &-container {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        top: 100%;
        left: 0;
        right: 0;
    }

    .suggestion-item {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
        
        span {
            color: $grayColor !important;
        }
        
        &:hover {
            background-color: #e9e9e9;
        }
        
        &-active {
            padding: 10px;
            cursor: pointer;
            background-color: $backgroundGray !important;
            color: #ffffff;
            border-bottom: 1px solid #d4d4d4;
            
            span {
                color: $grayColor !important;
            }
        }
    }
}