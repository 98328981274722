@import '../../../../../styles/base/color';

.navbar {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    background: $white !important;
    padding: 17px 0px 17px;
    box-shadow: 2px 2px 15px #ababab25;

    & > div {
        @media (max-width: 992px) {
            padding: 0 !important;
            max-width: none !important;
        }
    }

    & > div > div {
        width: auto;

        @media (max-width: 992px) {
            width: 100%;
            padding: 0 1.5rem;
            max-width: none !important;
        }
    }

    .logo-nav {
        height: 18px;
        width: 141px;
    }

    .navbar-toggler {
        box-shadow: none !important;
        color: $primary !important;
        font-size: 2rem;
        width: 3rem;
        padding: 0.2rem 0.55rem;

        .navbar-icon-active{
            display: flex;
        }

        .navbar-icon {
            display: none;
        }

        .navbar-icon-close {
            margin-left: 0.12rem;
        }

    }
    
    .navbar-nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1.2rem;

        @media (max-width: 992px) {
            margin-top: 20px;
            padding-right: 0;
        }

         
        li {
            padding: 0 15px;
            color: $primary;
            font-weight: 700;
            font-size: 1rem;
            text-align: center;
            width: auto;
            font-weight: 400 !important;

            @media (max-width: 1999px) {
                padding: 8px 15px;
            } 
            
            @media (max-width: 992px) {
                width: 100%;
                padding: 14px 15px;
                font-size: 1rem;
            } 

        }
        :hover {
            @media (max-width: 992px) {
                background-color: $backgroundGray;
            }
        }

        .nav-item-active {
            span {
                font-weight: 700 !important;
            }
        }
    }

    .change-language {
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 0.7rem;
        border-radius: 12px;
        padding: 0 0.3rem 0 0.4rem;

        // @media (max-width: 992px) {
        //         position: fixed;
        //         top: 1rem;
        //         right: 10.5rem;
        //         border: none;       
        //     }

        // @media (max-width: 740px) {
        //         position: fixed;
        //         top: 1rem;
        //         right: 10rem;
        //         border: none;       
        // }

        // @media (max-width: 700px) {
        //         position: fixed;
        //         top: 1rem;
        //         right: 9rem;
        //         border: none;       
        // }

        //  @media (max-width: 680px) {
        //         position: fixed;
        //         top: 1rem;
        //         right: 8rem;
        //         border: none;       
        // }

        // @media (max-width: 650px) {
        //         position: fixed;
        //         top: 1rem;
        //         right: 7rem;
        //         border: none;       
        //     }

        // @media (max-width: 600px) {
        //         position: fixed;
        //         top: 1rem;
        //         right: 5rem;
        //         border: none;       
        //     }

        @media (max-width: 992px) {
                position: fixed;
                top: 1rem;
                right: 4rem;
                border: none;       
            }
    
        &__show {
            @media (max-width: 992px) {
                opacity: 1;    
            }
        }

        &__hide {
            @media (max-width: 992px) {
                opacity: 0;    
            }
        }

        &__icon {
            color: $primary;
        }

        &__select {
            width: auto;
            padding: 0 !important;
            cursor: pointer;
            font-weight: 400 !important;
            border: none !important;
            outline: none !important;
        }

    }
    
    .btn {
        padding: 0.7rem 1.2rem !important;
        font-weight: 600;
        width: 9rem;
        
        @media (max-width: 992px) {
            padding: 0.8rem 2rem !important;
            margin-top: 1.7rem !important;
            margin-bottom: 1rem !important;
            font-size: 0.875rem !important;
            width: 11rem;
            height: 2.1rem;
        }
    }
}