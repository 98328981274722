@import '../../../../../styles/base/color';

.pagination {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;

    @media (max-width: 768px) {
        justify-content: space-between;
        margin-bottom: 39px;
    }

    li {
        margin: 0 7px;
    }

    .page {
        border-radius: 8px;
        background: transparent;
        color: $primary;
        padding: 7px 10px;
    
        @media (max-width: 768px) {
            margin: 0;
        }
    }
    
    .arrow {
        background-color: $white;
        border: 1px solid $primary;
        filter: drop-shadow(0px 0px 20px rgba(171, 171, 171, 0.25));
        border-radius: 8px;
        padding: 7px 10px;
    }
    
    .active > button {
        background: $primaryLight;
        border-radius: 8px;
    }
}