@import '../../../styles/base/color';

.survey-done {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &__container {
    box-shadow: 2px 2px 10px $boxShadow;
    border-radius: 32px;
    width: 900px;
    padding: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

    @media (max-width: 1300px) {
      width: 75%;
    }

    @media (max-width: 576px) {
      padding: 46px;
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 22px;

      &--h2, &--h3 {
        text-align: center;
      }
    
      &--h3 {
        color: $fontSecondary;

        @media (max-width: 768px) {
          font-size: 24px !important;
        }
      }
      
      &--h2 {
        @media (max-width: 768px) {
          font-size: 30px !important;
          line-height: 32px !important;
        } 
      }
    }
  }

}