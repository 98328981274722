@mixin skeletonBasicsAnimation($bgPrimary, $bgSecondary) {
    overflow: hidden;
    background: $bgPrimary;

    &::before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        animation: loading 1s infinite;
        background: linear-gradient(
            to right,
            transparent,
            $bgSecondary,
            transparent
        );
    }

    @keyframes loading {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
}

@mixin button($width, $height, $padding) {
    width: $width;
    height: $height;
    padding: $padding;
    color: $white;
    background-color: $primary;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: $primary-hover;
    }

    &:active {
        -webkit-transform: scale(0.99);
        transform: scale(0.99);
    }

    &:disabled {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.8;
        cursor: default;
    }
}
