@import '../../../../styles/base/color';


.blog {
    .post-list {
        margin-top: 100px;
        margin-bottom: 29px;
        
        @media (max-width: 768px) {
            margin-top: 46px;
            margin-bottom: 26px;
        }

        .post {
            .post-img {
                min-height: 450px;
                background: linear-gradient(0deg, rgba(33, 87, 50, 0.6), rgba(33, 87, 50, 0.6)), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                background-blend-mode: multiply, normal, normal;

                @media (max-width: 768px) {
                    min-height: 250px;
                }

                img {
                    position: absolute;
                    z-index: -1;
                }
            }

            .post-content {
                bottom: 27px;
                left: 20px;
                right: 20px;
                padding: 10px;

                h4, p { color: $white !important; }

                h4 {
                    @extend .truncated-text;
                    -webkit-line-clamp: 3; 
                }

                p {
                    @extend .truncated-text;
                    text-align: left !important;
                    -webkit-line-clamp: 4;
                }

                .truncated-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }

                .post-title {
                    display: flex;
                    align-items: flex-end;
                    min-height: 93px;
                }

                .post-body {
                    display: flex;
                    align-items: flex-end;
                    min-height: 72px;
                }
            }
        }
    }
}