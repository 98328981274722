@import '../../../../../styles/base/color';

.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rating input {
  display: none;
}
.rating label {
  display: inline-block;
  padding: 5px;
  font-size: 25px;
  color: $light-gray !important;
  cursor: pointer;
}
.rating input:checked ~ label {
  color: $primary !important;
}
.rating label:hover,
.rating label:hover ~ label {
  color: $primary !important;
}
