@import '../base/color';

.btn {
  font-weight: 300;

  @media screen and (max-width: 768px) {
    & {
      font-size: 14px !important;
    }
  }

  &.btn {
    line-height: 1 !important;
    padding: 12px 48px !important;
    font-weight: 700;
    border-radius: 12px;
    
    &-sm {
      padding: 0.5rem 1rem !important;
      border-radius: 12px !important;
      font-size: 0.875rem !important;
    }

    &-default {
      i {
        font-size: 18px;
        color: $fontPrimary !important;
        line-height: 1.3;
      }

      @media screen and (max-width: 768px) {
        & { padding: 0.75rem 0.5rem; }
      }
    }

    &-secondary {
      border: 1px solid $fontPrimary;

      &:hover { 
        background-color: $grayColor; 
        color: $white;
        opacity: .3; 
      }
    }

    &-link {
      padding: 5px !important;
      text-decoration: none !important;

      &:hover { text-decoration: underline !important; }
    }

    &-block { width: 100% !important; }
  }
  &.create-incident-submit-btn {
    position:  relative !important;
    min-width: 149px;
    min-height: 42px;
  }
}



