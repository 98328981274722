@import '../base/color';

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 1rem;

    label { margin-bottom: 5px;}
  }

  input::placeholder {
    color: $light-gray;
    font-weight: 400;
  }

  &-invalid { 
    color: $danger; 
    font-size: 0.9rem;
  }

}

.form-control:disabled, 
.form-control[readonly], 
select:disabled,
textarea:disabled,
.Mui-disabled {
  background-color: #e9ecef !important;
  opacity: 1;
  color: #212529 !important;
  -webkit-text-fill-color: unset !important;
}